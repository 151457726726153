import '../css/email.css';

const Email = () => {
  return (
    <div id='email'>
        <a href="mailto:rbrettparsons@gmail.com">rbrettparsons@gmail.com</a>
    </div>
  )
}

export default Email