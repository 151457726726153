import { Link } from 'react-router-dom'

const Dashboard = () => {
  return (
    <div>
        <h4>Version 1.0.0</h4>
        <Link to='/'>home</Link>
    </div>
  )
}

export default Dashboard